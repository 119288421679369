<template>
  <v-hover v-if="currentUser" v-slot:default="{ hover }">
    <v-card :class="{ 'on-hover': hover }">
      <v-system-bar window color="white">
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              text
              :class="{ 'show-btns': hover }"
              color="transparent"
              icon
              v-on="on"
              @click="$emit('edit')"
            >
              <v-icon :class="{ 'show-btns': hover }">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
      </v-system-bar>

      <v-list-item class="pt-5">
        <v-list-item-avatar
          class="mx-auto"
          color="grey"
          size="100"
        >
          <img
            :src="
              currentUser.photo
                ? `${apiUrl}/images/user/${currentUser.photo}`
                : `/avatar.png`
            "
            alt
          />
        </v-list-item-avatar>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="subtitle-1 font-weight-medium pb-2 text-center">
            {{ currentUser.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="subtitle-2 text-center pb-5">{{
            currentUser.email
          }}</v-list-item-subtitle>
          <v-list-item-subtitle class="subtitle-2 text-center pb-5">{{
            currentUser.telephone
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-hover>
</template>

<script>
import { API_URL } from '@/api'
import { mapGetters } from 'vuex'
export default {
  name: 'AuthCard',
  data: () => ({
    apiUrl: API_URL
  }),
  computed: {
    ...mapGetters({
      currentUser: 'auth/getCurrentUser'
    }),
    userId () {
      return this.$root.$data.userId
    }
  }
}
</script>